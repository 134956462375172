<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" 
          class="mb-md-0 mb-2">
          <label>Açıklama Turu</label>
          <b-form-group>
            <v-select
              v-model="teklifAciklamaTuruFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="teklifAciklamaTuruOptions"
              class="w-100"
              :reduce="val => val.value"
              placeholder="Açıklama Turu Seç" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>Arama</label>
          <b-form-input
            v-model="queryFilter"
            class="d-inline-block mr-1"
            :placeholder="$t('Ara...')" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import StaticOptions from '@/common/options/StaticOptions'

export default {
  data() {
    return {
     teklifAciklamaTuruOptions: StaticOptions.teklifAciklamaTuruOptions,
    }
  },
  computed: {
    teklifAciklamaTuruFilter: {
      get() {
        return this.$store.getters['teklifAciklamaYonetim/getTeklifAciklamaFilter'].teklifAciklamaTuru
      },
      set(value) {
        this.$store.commit('teklifAciklamaYonetim/setTeklifAciklamaFilter', { key: 'teklifAciklamaTuru', value })
      },
    },
    queryFilter: {
      get() {
        return this.$store.getters['teklifAciklamaYonetim/getTeklifAciklamaFilter'].query
      },
      set(value) {
        this.$store.commit('teklifAciklamaYonetim/setTeklifAciklamaFilter', { key: 'query', value })
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
