<template>
<div>
  <crud-button 
    :onClickList="fetchTeklifAciklamas"
    :onClickAddNew="addNew"
    />
  <teklif-aciklama-filter />
  <b-card
    no-body
    class="card-company-table"
  >
    <b-table
      :items="getTeklifAciklamaList"
      responsive
      :fields="tableColumns"
      class="mb-0"
      show-empty
      empty-text="Hiçbir eşleşen kayıt bulunamadı"
    >
      <!-- urungrup -->
      <template #cell(sAciklama)="data">
        <div class="d-flex align-items-center">
            <div class="font-weight-bolder" v-html="data.item.sAciklama" />
        </div>
      </template>

      <!-- category -->
      <template #cell(teklifAciklamaTuru)="data">
        <div class="d-flex align-items-center">
            <feather-icon
              :icon="resolveTurIcon(data.item.teklifAciklamaTuru)"
              size="18"
              class="mr-50"
              :class="`text-${resolveTurVarient(data.item.teklifAciklamaTuru)}`"
            />
          <span>{{ resolveTurName(data.item.teklifAciklamaTuru) }}</span>
        </div>
      </template>

      <!-- durum -->
      <template #cell(isActive)="data">
        <div class="text-nowrap">
          <b-form-checkbox
            :checked="data.item.isActive"
            class="custom-control-success"
            name="check-button"
            @change="deactivate(data.item.id)"
            >
            {{ $t('Aktif/Pasif') }}
          </b-form-checkbox>
        </div>
      </template>
      
      <template #cell(actions)="data">
        <div class="text-right">
          <!-- Update -->
          <feather-icon
            :id="`user-row-${data.item.id}-edit-icon`"
            @click="update(data.item)"
            icon="EditIcon"
            class="cursor-pointer text-primary"
            size="16"
          />

          <!-- Remove -->
          <feather-icon
            :id="`user-row-${data.item.id}-remove-icon`"
            icon="Trash2Icon"
            class="cursor-pointer mx-1 text-danger"
            size="16"
          />
        </div>
      </template>
    </b-table>
  </b-card>
  <save-teklif-aciklama v-if="modalObject.showModal" :modalObject="modalObject" />
</div>
</template>
<script>
// Alert
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

// custom
import TeklifAciklamaFilter from './TeklifAciklamaFilter.vue';
import SaveTeklifAciklama from './SaveTeklifAciklama.vue';

export default {
  components: {
    TeklifAciklamaFilter,
    SaveTeklifAciklama,
  },
  data() {
    return {
      tableColumns: [
        { label: 'Açıklama', key: 'sAciklama' },
        { label: 'Tür', key: 'teklifAciklamaTuru' },
        { label: 'Durum', key: 'isActive' },
        { label: 'Aksiyonlar', key: 'actions', class: 'text-right' },
      ],
      modalObject: {
        item: {},
        showModal: false,
        onSuccess: this.fetchTeklifAciklamas,
      },
    }
  },
  computed: {
    getTeklifAciklamaList() {
      return this.$store.getters['teklifAciklamaYonetim/getTeklifAciklamas'];
    },
  },
  methods: {
    update(item) {
      Object.assign(this.modalObject.item, item);
      this.modalObject.showModal = true;
    },
    addNew() {
      this.modalObject.item = { kullaniciTuru_Id: 2 };
      this.modalObject.showModal = true;
    },
    deactivate(id) {
      this.$store.dispatch('teklifAciklamaYonetim/toggleActiveTeklifAciklama', id)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
          } else {
            AlertService.error(this, response.message);
          }
        });
    },

    fetchTeklifAciklamas() {
      this.$store.dispatch('teklifAciklamaYonetim/fetchTeklifAciklamas');
    },

    resolveTurIcon(tur) {
      if (tur === 1) return 'AlignJustifyIcon'
      if (tur === 2) return 'AlignJustifyIcon'
      return 'AlignJustifyIcon'
    },
    resolveTurName(tur) {
      if (tur === 1) return 'Genel Açıklama'
      if (tur === 2) return 'Ürün Açıklama'
      return ''
    },

    resolveTurVarient(tur) {
      if (tur === 1) return 'primary'
      if (tur === 2) return 'warning'
      return ''
    },
  },
  mounted() {
    this.fetchTeklifAciklamas();
  },
};

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
